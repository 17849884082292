var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.screenSmall)?_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.create()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,1593813509)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.add-new')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.remove()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,false,2833217906)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delete-selected')))])])],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.company.addresses,"show-select":"","item-key":"tempId"},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.create()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.create')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.remove()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delete-selected')))])])]},proxy:true},{key:"body.prepend",fn:function(){return [(!_vm.screenSmall)?_c('tr',[_c('td'),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),(!_vm.screenMedium)?_c('td'):_vm._e(),_c('td',{attrs:{"colspan":"2"}})]):_vm._e()]},proxy:true},{key:"item.printing",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{on:{"input":function($event){return _vm.printingChanged(item)}},model:{value:(item.printing),callback:function ($$v) {_vm.$set(item, "printing", $$v)},expression:"item.printing"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.modify(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.modify')))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }