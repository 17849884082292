














































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CompanyAddressDialogProperties } from "./CompanyAddressDialogView.vue";
import { CompanyInfoProperties } from "./CompanyInfoView.vue";
import CommonUtil from "@/utilities/CommonUtil";


@Component({
    data: () => ({
        selected: [],
        filter: {
            name: "",
        },
    }),
})
export default class CustomerAddressView extends Vue {
    @Prop() private properties: CompanyInfoProperties;
    @Prop() private companyAddressDialog: CompanyAddressDialogProperties;

    private get headers() {
        return [
            {
                text: this.$t("text.name"),
                value: "name",
                width: this.screenSmall ? "100%" : this.screenMedium ? "75%" : "20%",
                sortable: false,
                visible: true,
                filter: (v) => {
                    if (!this.$data.filter.name) return true;
                    return v.includes(this.$data.filter.name);
                },
            },
            {
                text: this.$t("text.address"),
                value: "full",
                sortable: false,
                visible: !this.screenSmall && !this.screenMedium,
            },
            {
                text: this.$t("text.printing-address"),
                value: "printing",
                align: "center",
                width: this.screenSmall ? null : this.screenMedium ? "25%" : "15%",
                sortable: false,
                visible: !this.screenSmall,
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                align: "center",
                fixed: true,
                width: "120px",
                sortable: false,
                visible: true,
            },
        ].filter((x) => x.visible);
    }

	public get company() {
		return this.properties.company;
	}

    private get screenSmall() {
        return false; //disabled
    }

    private get screenMedium() {
        return false; //disabled
    }

	public created() {
		this.properties.events.subscribe('printing-changed', this.printingChanged);
	}

	public destroyed() {
		this.properties.events.remove('printing-changed', this.printingChanged);
	}

    public create() {
        this.companyAddressDialog.company = this.company;
		this.companyAddressDialog.address = { city: { state: { country: {} } } };
        this.companyAddressDialog.isNew = true;
        this.companyAddressDialog.visible = true;
    }

    public modify(address: any) {
        var address = CommonUtil.clone(address);
        this.companyAddressDialog.company = this.company;
        this.companyAddressDialog.address = address;
        this.companyAddressDialog.isNew = false;
        this.companyAddressDialog.visible = true;
    }

    public remove() {
        //delete selected
        const addresses = this.company.addresses;
        const selected = this.$data.selected;
        this.company.addresses = addresses.filter((x) => {
            for (var i = 0; i < selected.length; i++) {
                if (selected[i].tempId === x.tempId) {
                    return false;
                }
            }
            return true;
        });
        this.$data.selected = [];
    }

    public printingChanged(address: any) {
        const addresses = this.company.addresses;
        for (var i = 0; i < addresses.length; i++) {
            if (addresses[i].tempId !== address.tempId) {
				Vue.set(addresses[i], 'printing', false);
            }
        }
        if (!address.printing) {
			Vue.set(address, 'printing', true);
		}
    }
}
